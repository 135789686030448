type StorageType = "session" | "local"
type UseStorageReturnValue = {
    getItem: (key: string, type?: StorageType) => string
    setItem: (key: string, value: string, type?: StorageType) => boolean
    removeItem: (key: string, type?: StorageType) => void
}

export default function useStorage(): UseStorageReturnValue {
    const storageType = (type?: StorageType): "localStorage" | "sessionStorage" => `${type ?? "session"}Storage`

    const isBrowser: boolean = ((): boolean => typeof window !== "undefined")()

    const getItem = (key: string, type?: StorageType): string => {
        return isBrowser && window[storageType(type)] ? window[storageType(type)][key] : ""
    }

    const setItem = (key: string, value: string, type?: StorageType): boolean => {
        if (isBrowser && window[storageType(type)]) {
            window[storageType(type)].setItem(key, value)
            return true
        }

        return false
    }

    const removeItem = (key: string, type?: StorageType): void => {
        window[storageType(type)].removeItem(key)
    }

    return {
        getItem,
        setItem,
        removeItem,
    }
}
