import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useCookieconsent } from "../cookieinformation"
import useStorage from "../use-storage"

export { createAppLinkUrl } from "./util"

enum StorageName {
    GCLID = "ONECLICK_GCLID",
    MSCLKID = "ONECLICK_MSCLKID",
    FBCLID = "ONECLICK_FBCLID",
    ADTRACTIONID = "ONECLICK_ADTRACTIONID",
    SOURCE = "UTM_SOURCE",
    CAMPAIGN = "UTM_CAMPAIGN"
}

export function useOneclickTracking() {
    const { marketing, statistic } = useCookieconsent()
    const { getItem, setItem, removeItem } = useStorage()
    const { query } = useRouter()

    const [gclid, setGclid] = useState(getItem(StorageName.GCLID))
    const [msclkid, setMsclkid] = useState(getItem(StorageName.MSCLKID))
    const [fbclid, setFbclid] = useState(getItem(StorageName.FBCLID))
    const [adtractionId, setAdtractionId] = useState(getItem(StorageName.ADTRACTIONID))
    const [source, setSource] = useState(getItem(StorageName.SOURCE))
    const [campaign, setCampaign] = useState(getItem(StorageName.CAMPAIGN))

    useEffect(() => {
        if (marketing) {
            if(typeof query["gclid"] === "string") {
                setGclid(query["gclid"])
                setItem(StorageName.GCLID, query["gclid"])
            }
            if(typeof query["msclkid"] === "string") {
                setMsclkid(query["msclkid"])
                setItem(StorageName.MSCLKID, query["msclkid"])
            }
            if(typeof query["fbclid"] === "string") {
                setFbclid(query["fbclid"])
                setItem(StorageName.FBCLID, query["fbclid"])
            }
            if(typeof query["at_gd"] === "string") {
                setAdtractionId(query["at_gd"])
                setItem(StorageName.ADTRACTIONID, query["at_gd"])
            }
            // This parameter comes from ourselves at the /dl/ path when the user is on desktop
            if(typeof query["af_sub5"] === "string") {
                try {
                    const af_sub5Ids = Object.fromEntries(new URLSearchParams(query["af_sub5"]).entries())
                    if (af_sub5Ids["adtractionId"]) {
                        setAdtractionId(af_sub5Ids["adtractionId"])
                        setItem(StorageName.ADTRACTIONID, af_sub5Ids["adtractionId"])
                    }
                } catch(e) {
                    // Do nothing
                }
            }

            // In case the user clicks a onelink on desktop, and is redirected to lunar.app with following params:
            // /se/privat?clickid=3293CA08370C66AF0963CC2145CD2270BAFAC57F&af_c_id=1425092176&pid=adtraction_int&utm_campaign=adtraction
            if (typeof query["clickid"] === "string" && query["utm_campaign"] === "adtraction") {
                setAdtractionId(query["clickid"])
                setItem(StorageName.ADTRACTIONID, query["clickid"])
            }
        } else if (marketing === false && (gclid || msclkid)) {
            removeItem(StorageName.GCLID)
            removeItem(StorageName.MSCLKID)
            removeItem(StorageName.FBCLID)
            removeItem(StorageName.ADTRACTIONID)
            setGclid(undefined)
            setMsclkid(undefined)
            setFbclid(undefined)
            setAdtractionId(undefined)
        }

        if (statistic) {

            if (typeof query["utm_source"] === "string") {
                setSource(query["utm_source"])
            }
            if (typeof query["utm_campaign"] === "string") {
                setCampaign(query["utm_campaign"])
            }
        } else if (statistic === false && typeof query["utm_source"] === "string") {
            setSource(query["utm_source"])
            removeItem(StorageName.SOURCE)
        }
    }, [gclid, msclkid, fbclid, adtractionId, source, marketing, statistic, query, setItem, removeItem])

    const lcSource = source?.toLowerCase()
    let parsedSource = source || "Organic"
    if (gclid || lcSource?.includes("google") || lcSource?.includes("sem")) parsedSource = "Google Ads"
    if (msclkid) parsedSource = "Microsoft Ads"
    if (fbclid || lcSource?.includes("facebook") || lcSource?.includes("meta")) parsedSource = "Facebook Ads"
    if (lcSource?.includes("linkedin")) parsedSource = "LinkedIn Ads"
    if (adtractionId) parsedSource = "Adtraction partner"
    if (lcSource?.includes("mail")) parsedSource = "Email"

    return {
        ids: {
            ...(gclid ? { gclid } : {}),
            ...(msclkid ? { msclkid } : {}),
            ...(fbclid ? { fbclid } : {}),
            ...(adtractionId ? { adtractionId } : {}),
        },
        gclid,
        msclkid,
        fbclid,
        adtractionId,
        source,
        parsedSource,
        campaign,
    }
}
