import { getConsentFromCookieInformation } from "./cookieinformation"

interface EventProps {
    event: string
    [key: string]: any
}
declare global {
    interface Window {
        ga: any
        gaLoaded: boolean
        dataLayer: EventProps[]
        _paq: any[]
        piwikDataLayer: EventProps[]
        gtag: (...args: any[]) => void
        google_tag_manager?: any
    }
}

function hasDataLayer(window: Window) {
    return window && !!window.dataLayer
}

export const trackGTMPageView = (url: string) => {
    const pageEvent: EventProps = {
        event: "virtual-page-view",
        page: url,
    }

    if (hasDataLayer(window)) {
        window.dataLayer.push(pageEvent)
    }

    return pageEvent
}

export const trackGTMEvent = (event: string, extraProps?: Record<string, any>) => {
    const pageEvent: EventProps = {
        event: event,
        ...extraProps,
    }

    if (hasDataLayer(window)) {
        window.dataLayer.push(pageEvent)
    }

    return pageEvent
}

export const trackAppBadge = (appStore: "google-play-store" | "ios-app-store") => {
    const pageEvent: EventProps = {
        event: "app-badge-click",
        appStore: appStore,
        install_lead_type: "app-badge",
    }

    if (hasDataLayer(window)) {
        window.dataLayer.push(pageEvent)
    }

    return pageEvent
}


export const trackSmsLink = (gclid: string) => {
    const pageEvent: EventProps = {
        event: "send-link-by-sms",
        gclid,
    }

    if (hasDataLayer(window)) {
        window.dataLayer.push(pageEvent)
    }

    return pageEvent
}
// Returns the Google Client ID, concatenated with the current timestamp - used to backdate conversions events to the same sessions as the install lead
export async function getGaIds(): Promise<{gaClientId?: string, gaSessionId?: string}> {
    const { statistic } = getConsentFromCookieInformation()
    if (!statistic)
        return {}

    return new Promise(function(resolve) {
        // window.gaLoaded and the gaLoaded window event is setup in GTM
        if (window.gaLoaded)
            return resolve({
                gaClientId: _getGaClientIdWithTimestamp(),
                gaSessionId: _getGaSessionId(),
            })

        window.addEventListener("gaLoaded", function() {
            resolve({
                gaClientId: _getGaClientIdWithTimestamp(),
                gaSessionId: _getGaSessionId(),
            })
        })

        setTimeout(function() {
            resolve({})
        }, 500)
    })
}

function getCookie(cookieName: string) {
    const cookie = document.cookie.split("; ").find(cookie => cookie.startsWith(cookieName + "=") && cookie.includes("=deleted") === false)
    return cookie?.split("=")?.[1]
}

export function getGaClientIdFromCookie() {
    let clientId
    try {
        clientId = getCookie("_ga")?.split(".")?.slice(2)?.join(".")
    } catch (error) {
        //
    }
    return clientId
}

function _getGaClientIdWithTimestamp() {
    const clientId = getGaClientIdFromCookie()
    // Cut of the last couple of digits so the user facing string is not that terrifying
    // const timestamp = ("" + Date.now()).slice(0, 8)
    return clientId ? `${clientId}-${Date.now()}` : undefined
}

function _getGaSessionId() {
    let sessionId
    try {
        const fullCookie = getCookie("_ga_78GFWVM8FQ")
        sessionId = fullCookie?.split(".")?.[2]
    } catch (error) {
        //
    }

    return sessionId ?? undefined
}


export const piwikSetupSnippet = `
    window.piwikDataLayer = window.piwikDataLayer || [];
    var _paq = _paq || [];
    if (location.pathname.indexOf("/in-app/") > -1) {

        const piwikSiteId = "bfa80386-7687-4236-8ce3-25200f6ec258";
        (function(window, document, dataLayerName, id) {
            window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:"stg.start"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script');
            function stgCreateCookie(a,b,c){var d="";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d="; expires="+e.toUTCString();f="; SameSite=Strict"}document.cookie=a+"="+b+d+f+"; path=/"}
            var isStgDebug=(window.location.href.match("stg_debug")||document.cookie.match("stg_debug"))&&!window.location.href.match("stg_disable_debug");stgCreateCookie("stg_debug",isStgDebug?1:"",isStgDebug?14:-1);
            var qP=[];dataLayerName!=="dataLayer"&&qP.push("data_layer_name="+dataLayerName),isStgDebug&&qP.push("stg_debug");var qPString=qP.length>0?("?"+qP.join("&")):"";
            tags.async=!0,tags.src="https://lunar-inapp.containers.piwik.pro/"+id+".js"+qPString,scripts.parentNode.insertBefore(tags,scripts);
            !function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);"string"==typeof a[0]&&window[dataLayerName].push({event:n+"."+i+":"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,"ppms",["tm","cm"]);
            })(window, document, 'piwikDataLayer', piwikSiteId);

        const isCookieInformationConsentReady = function() { return window.CookieInformation && window.CookieInformation.getConsentGivenFor && window.CookieInformation.getConsentGivenFor("cookie_cat_statistic") !== undefined }

        const hasConsent = function() { return window.CookieInformation && window.CookieInformation.getConsentGivenFor && window.CookieInformation.getConsentGivenFor("cookie_cat_statistic") }


        const getPiwikConsent = function(cb) {
            window.ppms.cm.api("getComplianceSettings", function(res) {
                const isInitialised = typeof (res.consents.analytics && res.consents.analytics.status) !== "undefined"
                const hasMadeDecision = (res.consents.analytics && res.consents.analytics.status) > -1
                const hasConsent = res.consents.analytics && res.consents.analytics.status === 1
                cb(isInitialised, hasMadeDecision, hasConsent)
            })
        }

        const setPiwikConsent = function(newConsent) {
            getPiwikConsent(function(isInitialised, hasMadeDecision, hasConsent) {
                if (!hasMadeDecision || (hasMadeDecision && hasConsent !== newConsent)) {
                    if (!window.hasTrackedPopupImpression)
                        window.ppms.cm.api("trackMainFormView", console.log, console.error)

                    window.ppms.cm.api("setComplianceSettings", { consents: { analytics: { status: newConsent ? 1 : 0 } } } , console.log, console.error)
                }
            })
        }

        getPiwikConsent(function(isInitialised) {
            if (!isInitialised) {
                window.ppms.cm.api(
                    "setInitialComplianceSettings",
                    {
                        consents: ["analytics"],
                    },
                    console.log,
                    console.error,
                )
            }
        })


        if (!location.pathname.includes("/in-app/")) {
            const willCookiePopupShow = !document.cookie.includes("CookieInformationConsent")
            if (willCookiePopupShow) {
                window.hasTrackedPopupImpression = true;
                setTimeout(function () {window.ppms.cm.api("trackMainFormView", console.log, console.error)}, 1200)


                // window.addEventListener("click", function(e) {
                //     const clickTargetClass = e && e.target && e.target.className
                //     if (clickTargetClass && clickTargetClass.indexOf("decline") > -1)
                //         window.ppms.cm.api("trackRejectAllClick")

                //     if (clickTargetClass && clickTargetClass.indexOf("accept") > -1)
                //         window.ppms.cm.api("trackAgreeToAllClick")
                // })
            } else {
                function checkPopupVisibility() {
                    const popup = document.querySelector("#coiOverlay")
                    popup && !!popup.offsetParent
                }
                let increments = 0
                let intervalId = setInterval(function() {
                    if (checkPopupVisibility()) {
                        window.hasTrackedPopupImpression = true;
                        setTimeout(function () {window.ppms.cm.api("trackMainFormView", console.log, console.error)}, 1200)
                        window.clearInterval(intervalId)
                    } else if (++increments > 15) {
                        window.clearInterval(intervalId)
                    }
                }, 400)
            }

            if (isCookieInformationConsentReady()) {
                setPiwikConsent(hasConsent())
            } else {
                window.addEventListener("CookieInformationConsentGiven", function() {
                    const newConsent = hasConsent()
                    if (typeof newConsent !== "undefined")
                        setPiwikConsent(newConsent)
                }, false)
            }
        }
    }

`
