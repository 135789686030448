import "../assets/styles/app.scss"

import type { AppProps } from "next/app"

import NProgress from "nprogress"
import Router from "next/router"
import { useRouter } from "next/router"
import { useEffect } from "react"
import Script from "next/script"
import { convertLangToCulture } from "../src/lib/cookieinformation"
import { useOneclickTracking } from "../src/lib/oneclick"
import { languageMap } from "src/sanity/languages"
import { getGaClientIdFromCookie, trackGTMPageView, piwikSetupSnippet } from "src/lib/tagmanager"
import { BasePage } from "src/sanity/types"
import { TemplateType, DocTypeMap } from "src/templates"
import dynamic from "next/dynamic"

const PreviewProvider = dynamic(() => import("../src/components/preview-provider/PreviewProvider"))

Router.events.on("routeChangeStart", () => NProgress.start())
Router.events.on("routeChangeComplete", () => NProgress.done())
Router.events.on("routeChangeError", () => NProgress.done())

Router.events.on("routeChangeComplete", () => {
    if (location.hash) {
        // Ensure page scrolls down to the anchor
        // eslint-disable-next-line no-self-assign
        setTimeout(() => location = location, 10); setTimeout(() => location = location, 250); setTimeout(() => location = location, 500)
    }
})

let lastTrackedPath = typeof window !== "undefined" ? location.pathname + location.search : ""
function trackPiwikPageView() {
    if (typeof window["_paq"] !== "undefined") {
        window["_paq"].push(["setCustomDimensionValue", 3, getGaClientIdFromCookie()])
        window["_paq"].push(["setCustomUrl", location.href ])
        window["_paq"].push(["setDocumentTitle", document.title ])
        window["_paq"].push(["trackPageView"])
    }
}

interface AppPropsWithPageProps extends AppProps {
    pageProps: BasePage<DocTypeMap[TemplateType]>
}


function Lunar({ Component, pageProps }: AppPropsWithPageProps) {
    const router = useRouter()

    function updateHtmlLang() { document.documentElement.lang = languageMap[pageProps?.globalSettings?.lang]?.intlLocale?.split("-")[0] }

    useEffect(() => {
        Router.events.on("routeChangeComplete", updateHtmlLang)

        return () => Router.events.off("routeChangeComplete", updateHtmlLang)
    }, [])

    // Ensure oneclick is setup
    useOneclickTracking()

    useEffect(() => {
        // https://medium.com/hackernoon/removing-that-ugly-focus-ring-and-keeping-it-too-6c8727fefcd2
        const onKeyDown = ({ keyCode }: KeyboardEvent) => {
            if (keyCode === 9) {
                document.body.classList.add("user-is-tabbing")
                window.removeEventListener("keydown", onKeyDown)
                window.addEventListener("mousedown", onMouseDown)
            }
        }
        const onMouseDown = () => {
            document.body.classList.remove("user-is-tabbing")
            window.removeEventListener("mousedown", onMouseDown)
            window.addEventListener("keydown", onKeyDown)
        }

        window.addEventListener("keydown", onKeyDown)

        // https://github.com/vercel/next.js/issues/17464 hacky solution until fixed in a future next version
        const elements = document.querySelectorAll("head link[rel=stylesheet]")
        for (const el of elements as any) {
            if (el.hasAttribute("data-n-p")) {
                el.removeAttribute("data-n-p")
            }
        }

        return () => {
            window.removeEventListener("keydown", onKeyDown)
            window.removeEventListener("mousedown", onMouseDown)
        }
    }, [])

    useEffect(() => {
        const onRouteChangeComplete = () => {
            const path = location.pathname + location.search
            if (lastTrackedPath && (lastTrackedPath !== path)) {
                // We only track from the second page view, as the first page view is triggered by consent initialization
                trackGTMPageView(path)
                trackPiwikPageView()
            }
            lastTrackedPath = path
        }
        router.events.on("routeChangeComplete", onRouteChangeComplete)
        return () => {
            router.events.off("routeChangeComplete", onRouteChangeComplete)
        }
    }, [])

    return (
        <>
            <script id="setup-consent-mode" dangerouslySetInnerHTML={{
                __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag() {
                    dataLayer.push(arguments);
                    }
                    gtag('consent', 'default', {
                    ad_storage: 'denied',
                    analytics_storage: 'denied',
                    wait_for_update: 1500,
                    });
                    gtag('set', 'ads_data_redaction', true);`.replace(/[\s\n]+/g, " "),
            }} />
            <script dangerouslySetInnerHTML={{ __html: piwikSetupSnippet }} />
            {pageProps?.preview !== true && pageProps?.docType !== "inAppPage" && (
                <>
                    <Script
                        strategy="beforeInteractive"
                        id="CookieConsent"
                        src="https://policy.app.cookieinformation.com/uc.js"
                        data-culture={convertLangToCulture(pageProps?.globalSettings?.lang || "en")}
                        type="text/javascript"
                    />
                    {/* eslint-disable-next-line @next/next/next-script-for-ga */}
                    <script id="googletagmanager" dangerouslySetInnerHTML={{
                        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-PDJPBRS');`,
                    }} />
                </>
            )}

            {!pageProps?.preview ? (
                <Component {...(pageProps as any)} />
            ) : (
                <PreviewProvider token={pageProps.previewData.token} >
                    <Component {...(pageProps as any)} />
                </PreviewProvider>
            )}
        </>
    )
}

export default Lunar
