var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d65b828aa926ed61ac1632952e4b3fc5ed160745"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"
import { onConsentReady, getConsentFromCookieInformation } from "src/lib/cookieinformation"

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || "unknown"

// Set User ID from Google Analytics if available
const googleAnalyticsID = (window as (typeof window) & {CookieInformation: any})?.CookieInformation?._getCookieValue("_gid")
const randomID = Math.random().toString(16).slice(2) // Random ID is alright, as we use SPA - it will be a "Session ID"
Sentry.setUser({ id: googleAnalyticsID || randomID })

Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    environment: SENTRY_ENVIRONMENT,
    dsn: SENTRY_DSN || "https://c04e8810b769495f93af9a31728abbfa@o448303.ingest.sentry.io/6070939",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.01,
    ignoreErrors: ["ResizeObserver loop limit exceeded", "ResizeObserver loop completed with undelivered notifications."],
    beforeSend(event, hint) {
        const error: any = hint.originalException
        const errorMessage = typeof error === "string" ? error : error?.message

        if (!location.hostname.includes("lunar.app"))
            return null

        // CUR-86: iPhone will automatically wrap phone numbers in <a href="tel:"> which messes with hydration
        // We mute the non serious error from Sentry
        const iPhoneUA = navigator.userAgent.includes("iPhone")
        if (iPhoneUA && errorMessage?.includes("Minified React error #4"))
            return null

        // Sleep for 2500ms to avoid sending Network interrupt errors caused by the user navigating away from lunar.app
        return new Promise(resolve => setTimeout(() => resolve(event), 2500))
    },
    denyUrls: [
        // Browser extensions
        /extension\//i,
        /^chrome:\/\//i,
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});

(async function() {
    await onConsentReady()
    const { statistic } = getConsentFromCookieInformation()
    Sentry.setContext("Cookie Consent", { hasGivenConsent: statistic } )
})()
